.contact-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;

    .contact-content {
        p {
            font-family: TypewalkMono1915Medium;
            font-size: 16px;
        }
    }
}