.portfolio-container {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .portfolio-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: 20px;
        flex-wrap: wrap;
        padding-top: 40px;

        .portfolio-item {
            min-width: 200px;
            max-width: 250px;
            height: auto;
            position: relative;

            img {
                width: 100%;
                height: 100%;
            }

            .portfolio-item-label {
                position: absolute;
                text-transform: uppercase;
                bottom: 0px;
                left: 50%;
                transform: translate(-50%, -50%);
                color: #fff;
                font-size: 12px;
                font-weight: 600;
                text-align: center;
                width: 100%;
            }
        }
    }

}