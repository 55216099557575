.member-card-container {
    display: flex;
    flex-direction: column;

    .member-card-container-image {
        width: 240px;
        height: 240px;
        border: 3px solid #fff;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }


        @media (max-width: 1000px) {
            width: 210px;
            height: 210px;
        }

        @media (max-width: 720px) {
            width: 300px;
            height: 300px;
        }
    }

    .member-card-info {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 4px;

        .member-card-info-name {
            text-transform: capitalize;
            font-size: 20px;
            font-weight: 600;
        }

        .member-card-info-title {}

        .member-card-info-twitter {

            img {
                width: 16px;
                height: 16px;
            }
        }
    }
}