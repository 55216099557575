.home-container {
    .home-content {
        padding-bottom: 16px;
        font-family: TypewalkMono1915Medium;
        line-height: 1.2;
        font-size: 20px;
        @media (max-width: 570px) {
            font-size: 16px;
          }
    }
}