.writing-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;

    .writing-content {
        p {
            font-family: TypewalkMono1915Medium;
            font-size: 18px;
        }
    }

}