.team-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    overflow-y: auto;

    .team-wrapper {
        display: flex;
        flex-wrap: wrap;
        padding: 0;
        list-style: none;
        column-gap: 8px;
        row-gap: 8px;

        .team-item {
            width: calc(33% - 5.333px);
            max-width: calc(33% - 5.333px);
            margin-bottom: 20px;
        }

        @media (max-width: 1000px) {
            .team-item {
                width: calc(50% - 4px);
                max-width: calc(50% - 4px);
            }
        }

        @media (max-width: 720px) {
            .team-item {
                width: calc(100%);
                max-width: calc(100%);
            }
        }
    }
}