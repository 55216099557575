body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Paparazzi';
  src: local('Paparazzi'), url('./assets/Paparazzi.ttf') format('woff');
}

@font-face {
  font-family: 'TypewalkMono1915Medium';
  src: local('TypewalkMono1915Medium'), url('./assets/TypewalkMono1915-Medium-Demo.otf') format('woff');
}

@font-face {
  font-family: 'TypewalkMono1915Thin';
  src: local('TypewalkMono1915Thin'), url('./assets/TypewalkMono1915-ThinDemo.otf') format('woff');
}

@font-face {
  font-family: 'TarzanaNarrow';
  src: local('TarzanaNarrow'), url('./assets/TarzanaNarrow.ttf') format('woff');
}