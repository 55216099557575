.container {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: black;
  color: #fff;

  .main {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    // max-width: 1024px;
    width: 100%;

    .menu-container {
      grid-column-start: 1;
      grid-column-end: 3;
      max-height: 100%;
      // background-color: azure;
      display: flex;
      flex-direction: column;
      gap: 28px;
      height: fit-content;
      position: sticky;
      top: 0px;
      padding-top: 20px;
      min-width: 200px;

      @media (max-width: 570px) {
        display: none;
        grid-column-start: 1;
        grid-column-end: 1;
      }

      .menu-list {
        list-style: none;

        .menu-item {
          width: 100%;
          // background-color: #fff;
          cursor: pointer;
          font-family: TypewalkMono1915Medium;

          a {
            color: #fff;
            text-decoration: none;
            width: 100%;
            display: block;
            padding: 8px 8px 8px 20px;
            box-sizing: border-box;
            font-size: 20px;
          }

          a:hover {
            text-decoration: underline;
          }
        }

        .menu-item.active {
          a {
            color: #000;
            background-color: #fff;
          }
        }
      }
    }

    .page-container {
      grid-column-start: 3;
      grid-column-end: 13;
      display: flex;
      flex-direction: column;
      align-items: center;
      // border-left: 1px solid #fff;
      padding-top: 60px;

      .mobile.menu-logo {
        display: none;
      }

      @media (max-width: 570px) {
        grid-column-start: 1;
        grid-column-end: 13;

        .mobile.menu-logo {
          display: flex;
          padding-bottom: 30px;
        }
      }

      .page-wrapper {
        max-width: 1024px;
        padding: 40px;

        .page-item {
          display: flex;
          flex-direction: column;
          align-items: start;
          padding-bottom: 50px;

          .page-item-content {
            width: 100%;
          }
        }
      }
    }
  }
}

p {
  margin: 0;
}

.underline {
  max-width: 160px;
  width: 100%;
  height: 2px;
  background-color: #fff;
  display: block;
  margin: 8px 0 16px;

}

.label {
  font-size: 40px;
  font-weight: 500;
  text-transform: uppercase;
  font-family: Paparazzi;
}

.menu-logo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .menu-logo-image {
    padding: 24px 44px 0;

    img {
      width: 100%;
      height: auto;
    }
  }

  .menu-logo-text {
    text-align: center;

    .menu-logo-text-large {
      font-size: 28px;
      font-weight: 700;
    }

    .menu-logo-text-small {
      font-size: 12px;
      letter-spacing: 4px;
    }
  }
}